import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MapComponent from './MapComponent'; // Adjust the import path as needed
import './App.css'; // Your CSS file

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div>
        <Routes>
          <Route path="/" element={<MapComponent />} />
          {/* You can add more routes here for other components/pages */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
