// ./hooks/useWebSocket.js
import { useEffect, useRef, useState } from 'react';

const useWebSocket = (url, onMessageHandler) => {
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        // Create a WebSocket connection only once when the hook is mounted
        const socket = new WebSocket(url);
        socketRef.current = socket;

        socket.onopen = () => {
            setIsConnected(true);
        };

        socket.onclose = () => {
            setIsConnected(false);
        };

        socket.onmessage = (event) => {
            if (onMessageHandler) {
                try {
                    const message = JSON.parse(event.data);
                    onMessageHandler(message);
                } catch (error) {
                    console.error(`Error processing message: ${event.data}`, error);
                }
            }
        };

        // Cleanup function that runs only on unmount
        return () => {
            if (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING) {
                socket.close();
            }
        };
    }, [url]); // Only re-run if the `url` changes

    return { socketRef, isConnected };
};

export default useWebSocket;
