import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke, Text, Icon } from 'ol/style';
import { IMAGE_SIZE } from '../constants';

/**
 * Creates a marker layer and sets its reference.
 * @param {object} markerLayerRef - Reference to store the marker layer.
 * @returns {VectorLayer} - The OpenLayers VectorLayer object for markers.
 */
export const createMarkerLayer = (markerLayerRef) => {
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayer({
        source: markerSource,
        zIndex: 2,
    });
    markerLayerRef.current = markerLayer; // Set the marker layer reference
    return markerLayer;
};

/**
 * Adds or updates a marker on the marker layer.
 * @param {object} markerLayerRef - Reference to the marker layer.
 * @param {object} marker - The marker data to be added or updated.
 */
export const addOrUpdateMarker = (markerLayerRef, marker) => {
    const { id, name, type, posX, posY, attributes = {} } = marker;

    if (!markerLayerRef.current) return;

    const markerSource = markerLayerRef.current.getSource();
    let existingFeature = markerSource.getFeatureById(id);

    if (existingFeature) {
        // Update existing marker properties
        existingFeature.setGeometry(new Point([posX, posY - IMAGE_SIZE]));
        existingFeature.set('name', name);
        existingFeature.set('type', type);
        existingFeature.set('attributes', attributes);
    } else {
        // Add new marker if it does not exist
        const newFeature = new Feature({
            geometry: new Point([posX, posY - IMAGE_SIZE]),
            id,
            name,
            type,
            attributes,
        });

        newFeature.setId(id);
        markerSource.addFeature(newFeature);
    }
};


/**
 * Updates the visibility of markers based on the selected filter.
 * @param {object} markerLayerRef - Reference to the marker layer.
 * @param {array} selectedTypes - Array of marker types to display (e.g., ['Item', 'Entity', 'Player']).
 */
export const updateMarkerVisibility = (markerLayerRef, selectedTypes) => {
    if (!markerLayerRef.current) return;

    const markerSource = markerLayerRef.current.getSource();
    const features = markerSource.getFeatures();

    features.forEach((feature) => {
        const featureType = feature.get('type');
        // const featureId = feature.getId();
        const isVisible = selectedTypes.includes('All') || selectedTypes.includes(featureType);

        if (isVisible) {
            // Show marker by setting the style
            feature.setStyle(getMarkerStyle(featureType, feature.get('name'), feature.get('attributes')));
            // console.log(`Setting style for Feature ID: ${featureId}`);
        } else {
            // Hide marker by setting the style to null
            feature.setStyle(null);
            // console.log(`Hiding Feature ID: ${featureId}`);
        }
    });
};

/**
 * Returns the style for a marker based on its type.
 * @param {string} type - The type of the marker (e.g., 'Item', 'Entity', 'Player').
 * @param {string} name - The name of the marker.
 * @returns {Style} - The OpenLayers Style object for the marker.
 */
export const getMarkerStyle = (type, name, attributes) => {
    let markerStyle;

    if (type === 'Player') {
        const health = attributes && attributes.health ? attributes.health : 0; // Get health if available, otherwise default to 0
        let color;

        // Determine marker color based on health
        if (health >= 51) {
            color = 'green';
        } else if (health >= 31) {
            color = 'yellow';
        } else {
            color = 'red';
        }

        // Create an SVG representation for the arrow with the specified color
        const svgArrow = `
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 6">
                <path d="M0,0 L0,6 L10,3 L0,0" fill="${color}" stroke="black" stroke-width="1" />
            </svg>
        `;
        const svgUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgArrow)}`;

        markerStyle = new Style({
            image: new Icon({
                src: svgUrl,
                scale: 1,
                rotation: attributes && attributes.heading ? attributes.heading * (Math.PI / 180) : 0, // Convert heading to radians for rotation
                anchor: [0.5, 0.5],
                rotateWithView: true,
            }),
            text: new Text({
                text: name,
                offsetX: 15,
                textAlign: 'left',
                fill: new Fill({ color: 'white' }),
                stroke: new Stroke({ color: 'black', width: 2 }),
                font: '12px Arial',
            }),
        });
    } else if (type === 'Item') {
        markerStyle = new Style({
            image: new Icon({
                src: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="5" fill="yellow" stroke="black" stroke-width="1" />
                    </svg>
                `)}`,
                scale: 1,
                anchor: [0.5, 0.5],
            }),
            text: new Text({
                text: name,
                offsetX: 15,
                textAlign: 'left',
                fill: new Fill({ color: 'white' }),
                stroke: new Stroke({ color: 'black', width: 2 }),
                font: '12px Arial',
            }),
        });
    } else if (type === 'Entity') {
        markerStyle = new Style({
            image: new Icon({
                src: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="5" fill="green" stroke="black" stroke-width="1" />
                    </svg>
                `)}`,
                scale: 1,
                anchor: [0.5, 0.5],
            }),
            text: new Text({
                text: name,
                offsetX: 15,
                textAlign: 'left',
                fill: new Fill({ color: 'white' }),
                stroke: new Stroke({ color: 'black', width: 2 }),
                font: '12px Arial',
            }),
        });
    }

    return markerStyle;
};

