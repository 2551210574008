import React, { useEffect, useRef, useState, useCallback } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileImage from 'ol/source/TileImage';
import { defaults as defaultControls } from 'ol/control';
import { TileGrid } from 'ol/tilegrid';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { LineString } from 'ol/geom';
import Feature from 'ol/Feature';
import { Style, Stroke } from 'ol/style';
import Overlay from 'ol/Overlay';
import './MapComponent.css';
import { createMarkerLayer, addOrUpdateMarker, updateMarkerVisibility } from './utils/markerUtils';
import useWebSocket from './hooks/useWebSocket';
import { IMAGE_SIZE, WEBSOCKET_URL } from './constants';

const MapComponent = () => {
    const mapRef = useRef();
    const markerLayerRef = useRef(null);
    const gridLayerRef = useRef(null);
    const dropdownButtonRef = useRef(null);
    const dropdownOptionsRef = useRef(null);

    const tileSourceConfig = [
        { id: 'source1', location: 'chernarusplus', name: 'topo_hd' },
        { id: 'source2', location: 'chernarusplus', name: 'terrain' },
    ];
    const initialTileIndex = tileSourceConfig.findIndex(config => config.id === localStorage.getItem('tileSource')) || 0;

    const [currentTileIndex, setCurrentTileIndex] = useState(initialTileIndex);
    const [nextTileIndex, setNextTileIndex] = useState((initialTileIndex + 1) % tileSourceConfig.length);
    const [tileSource, setTileSource] = useState(tileSourceConfig[initialTileIndex].id);
    const [map, setMap] = useState(null);
    const [isCoordinatesVisible, setIsCoordinatesVisible] = useState(true);
    const [isDropdownVisible, setIsDropdownVisible] = useState(true);
    const [isMarkerFilterOpen, setIsMarkerFilterOpen] = useState(false);
    const [currentCoordinates, setCurrentCoordinates] = useState('');
    const [selectedMarkerTypes, setSelectedMarkerTypes] = useState(['All', 'Player', 'Entity', 'Item']);
    const selectedMarkerTypesRef = useRef(selectedMarkerTypes);

    // Update the ref whenever the state changes
    useEffect(() => {
        selectedMarkerTypesRef.current = selectedMarkerTypes;
    }, [selectedMarkerTypes]);

    // Define the tile layer creation function
    const createTileLayer = useCallback((sourceId) => {
        const tileSource = tileSourceConfig.find(config => config.id === sourceId);

        if (!tileSource) {
            console.error(`Tile source with id ${sourceId} not found`);
            return null;
        }

        const { location, name } = tileSource;

        const tileUrlFunction = (tileCoord) => {
            const url = `${process.env.PUBLIC_URL}/tiles/${location}/${name}/${tileCoord[0]}/${tileCoord[1]}/${-1 - tileCoord[2]}.png`;
            return url;
        };

        return new TileLayer({
            source: new TileImage({
                tileGrid: new TileGrid({
                    extent: [0, -IMAGE_SIZE, IMAGE_SIZE, 0],
                    origin: [0, -IMAGE_SIZE],
                    resolutions: [64, 32, 16, 8, 4, 2, 1],
                    tileSize: [256, 256],
                }),
                tileUrlFunction: tileUrlFunction,
            }),
            zIndex: 0,
        });
    }, [tileSourceConfig]);

    // Define the grid layer creation function
    const createGridLayer = useCallback(() => {
        const gridSource = new VectorSource();
        const offset = 1000;

        for (let x = offset; x <= IMAGE_SIZE; x += 1000) {
            gridSource.addFeature(new Feature(new LineString([[x, 0], [x, -IMAGE_SIZE]])));
        }

        for (let y = -offset; y >= -IMAGE_SIZE; y -= 1000) {
            gridSource.addFeature(new Feature(new LineString([[0, y], [IMAGE_SIZE, y]])));
        }

        const gridLayer = new VectorLayer({
            source: gridSource,
            zIndex: 1,
            style: new Style({
                stroke: new Stroke({
                    color: 'rgba(255, 255, 255, 0.7)',
                    width: 1,
                }),
            }),
        });

        gridLayerRef.current = gridLayer;
        return gridLayer;
    }, []);

    // Stable callback for handling WebSocket messages
    const handleWebSocketMessage = useCallback((message) => {
        if (Array.isArray(message)) {
            const newMarkerIds = message.map(marker => marker.id);

            message.forEach((marker) => {
                addOrUpdateMarker(markerLayerRef, marker);
            });

            // Remove markers that are not in the new data
            if (markerLayerRef.current) {
                const markerSource = markerLayerRef.current.getSource();
                const existingFeatures = markerSource.getFeatures();

                existingFeatures.forEach((feature) => {
                    const featureId = feature.getId();
                    if (!newMarkerIds.includes(featureId)) {
                        markerSource.removeFeature(feature);
                    }
                });
            }

            // Ensure the visibility is updated after all changes using the latest state
            updateMarkerVisibility(markerLayerRef, selectedMarkerTypesRef.current);
        } else {
            console.error("Received invalid data: not an array", message);
        }
    }, []);

    const { socketRef, isConnected } = useWebSocket(WEBSOCKET_URL, handleWebSocketMessage);

    useEffect(() => {
        if (map) {
            const layers = map.getLayers();
            let tileLayer = null;

            // Find the existing tile layer
            layers.forEach(layer => {
                if (layer instanceof TileLayer && layer.getSource() instanceof TileImage) {
                    tileLayer = layer;
                }
            });

            if (tileLayer) {
                // Remove the existing tile layer
                map.removeLayer(tileLayer);
            }

            // Create a new tile layer based on the updated tileSource
            const newTileLayer = createTileLayer(tileSource);
            map.addLayer(newTileLayer);
        }
    }, [tileSource]); // Depend on tileSource only

    // Handle marker click
    useEffect(() => {
        if (map) {
            // Create a popup overlay
            const popupElement = document.getElementById('popup');
            const popup = new Overlay({
                element: popupElement,
                positioning: 'bottom-center',
                stopEvent: false,
                offset: [0, -10],
            });
            map.addOverlay(popup);

            const handleMarkerClick = (event) => {
                let isMarkerClicked = false;

                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    if (feature && feature.get('type')) {
                        // Extract feature attributes and show them in the popup
                        const attributes = feature.get('attributes') || {};
                        const coordinates = feature.getGeometry().getCoordinates();
                        popup.setPosition(coordinates);

                        // Update popup content
                        const popupContent = document.getElementById('popup-content');
                        if (popupContent) {
                            popupContent.innerHTML = `Attributes: ${JSON.stringify(attributes, null, 2)}`;
                        }

                        // Set flag indicating a marker was clicked
                        isMarkerClicked = true;
                    }
                });

                // If a marker was clicked, show the popup, otherwise hide it
                if (!isMarkerClicked) {
                    popup.setPosition(undefined); // Hide the popup by setting position to undefined
                }
            };

            // Add single click event listener to the map
            map.on('singleclick', handleMarkerClick);

            return () => {
                // Clean up the event listener and remove the popup on component unmount
                map.un('singleclick', handleMarkerClick);
                map.removeOverlay(popup);
            };
        }
    }, [map]);




    // Handle tile selector click to cycle through tilesets
    const handleTileSelectorClick = () => {
        const newCurrentIndex = (currentTileIndex + 1) % tileSourceConfig.length;
        setCurrentTileIndex(newCurrentIndex);
        setTileSource(tileSourceConfig[newCurrentIndex].id);
        localStorage.setItem('tileSource', tileSourceConfig[newCurrentIndex].id);

        // Update nextTileIndex to point to the next tileset in sequence
        setNextTileIndex((newCurrentIndex + 1) % tileSourceConfig.length);
    };

    // Map and coordinate display logic
    useEffect(() => {
        const inactivityDuration = 3000;
        let inactivityTimer;

        const resetTimer = () => {
            setIsCoordinatesVisible(true);
            setIsDropdownVisible(true);
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                setIsCoordinatesVisible(false);
                setIsDropdownVisible(false);
                setIsMarkerFilterOpen(false);
            }, inactivityDuration);
        };

        const initialMap = new Map({
            controls: defaultControls(),
            target: mapRef.current,
            layers: [
                createTileLayer(tileSource),
                createGridLayer(),
                createMarkerLayer(markerLayerRef),
            ],
            view: new View({
                center: [IMAGE_SIZE / 2, -(IMAGE_SIZE / 2)],
                zoom: 13,
                constrainResolution: true,
                constrainExtent: [0, -IMAGE_SIZE, IMAGE_SIZE, 0],
            }),
        });

        const handleMouseMove = (event) => {
            const coordinate = initialMap.getEventCoordinate(event.originalEvent);
            const formattedCoordinates = `(${Math.round(coordinate[0])}, ${Math.round(coordinate[1]) + IMAGE_SIZE})`;
            setCurrentCoordinates(formattedCoordinates);
        };

        initialMap.on('pointermove', handleMouseMove);

        setMap(initialMap);

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);

        return () => {
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            clearTimeout(inactivityTimer);
            initialMap.setTarget(undefined);
            initialMap.un('pointermove', handleMouseMove);
        };
    }, [tileSource]);

    // Handle changes in the marker filter selection
    const handleMarkerFilterChange = (type) => {
        setSelectedMarkerTypes((prevSelectedTypes) => {
            let newSelectedTypes = [...prevSelectedTypes];

            if (type === 'All') {
                if (newSelectedTypes.includes('All')) {
                    newSelectedTypes = [];
                } else {
                    newSelectedTypes = ['All', 'Player', 'Entity', 'Item'];
                }
            } else {
                if (newSelectedTypes.includes(type)) {
                    newSelectedTypes = newSelectedTypes.filter((t) => t !== type);
                } else {
                    newSelectedTypes.push(type);
                }

                if (newSelectedTypes.length === 3 && !newSelectedTypes.includes('All')) {
                    newSelectedTypes.push('All');
                } else if (newSelectedTypes.includes('All') && newSelectedTypes.length < 4) {
                    newSelectedTypes = newSelectedTypes.filter((t) => t !== 'All');
                }
            }

            return newSelectedTypes;
        });
    };



    return (
        <div style={{ display: 'flex', position: 'relative' }}>
            {isDropdownVisible && (
                <div
                    style={{
                        position: 'absolute',
                        right: '20px',  // Move to the right of the page
                        top: '20px',    // Adjust to the top
                        zIndex: 1002,
                    }}
                >
                    <div
                        onClick={handleTileSelectorClick}
                        style={{
                            width: '75px',
                            height: '75px',
                            borderRadius: '50%',
                            backgroundImage: `url(${process.env.PUBLIC_URL}/tiles/${tileSourceConfig[nextTileIndex].location}/${tileSourceConfig[nextTileIndex].name}/0/0/0.png)`,
                            backgroundSize: 'cover',
                            cursor: 'pointer',
                            border: '2px solid #555',
                            marginBottom: '10px'
                        }}
                    />
                </div>
            )}

            {isDropdownVisible && (
                <div
                    style={{
                        position: 'absolute',
                        left: '10px',
                        top: '75px',
                        zIndex: 1002,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        className="dropdown"
                        ref={(el) => (dropdownButtonRef.current = el)}
                        onClick={() => setIsMarkerFilterOpen(!isMarkerFilterOpen)}
                        style={{
                            cursor: 'pointer',
                            padding: '8px',
                            border: '1px solid #555',
                            backgroundColor: '#333',
                            color: '#fff',
                            borderRadius: '4px',
                            textAlign: 'left',
                            display: 'inline-block',
                        }}
                    >
                        Marker Filters ˅

                        {/* Dropdown options */}
                        <div
                            ref={(el) => (dropdownOptionsRef.current = el)}
                            style={{
                                display: isMarkerFilterOpen ? 'block' : 'none',
                                border: '1px solid #555',
                                position: 'absolute',
                                backgroundColor: '#333',
                                color: '#fff',
                                borderRadius: '4px',
                                zIndex: 1003,
                                maxHeight: '150px',
                                overflowY: 'auto',
                            }}
                        >
                            {/* Render filter options here */}
                            {['All', 'Player', 'Entity', 'Item'].map((type) => (
                                <div
                                    key={type}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkerFilterChange(type);
                                    }}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px',
                                        backgroundColor: selectedMarkerTypes.includes(type) ? '#555' : '#333',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #444',
                                        color: '#fff',
                                    }}
                                >
                                    <span
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '8px',
                                            border: '1px solid #fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: selectedMarkerTypes.includes(type) ? '#fff' : 'transparent',
                                            color: selectedMarkerTypes.includes(type) ? '#000' : 'transparent',
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {selectedMarkerTypes.includes(type) ? 'x' : ''}
                                    </span>

                                    {type}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div id="map" ref={mapRef} className="map" style={{ height: '100vh', border: '1px solid #888', flexGrow: 1 }} />

            {/* Add the popup element */}
            <div id="popup" className="ol-popup">
                <div id="popup-content"></div>
            </div>

            {/* Mouse position display */}
            <div id="mouse-position" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 1002 }}>
                {isCoordinatesVisible && (
                    <span className='custom-mouse-position'>{currentCoordinates}</span>
                )}
            </div>

            {/* Status indicator */}
            <div
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: isConnected ? 'green' : 'red',
                    border: '1px solid #111',
                    zIndex: 1002,
                }}
                title={isConnected ? 'Connected' : 'Disconnected'}
            />
        </div>
    );
};

export default MapComponent;
